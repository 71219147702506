import router from '@/router'
// eslint-disable-next-line object-curly-newline
import { getCurrentInstance, reactive, toRefs, watch } from '@vue/composition-api'
import axios from '@/libs/axios'
import { stringify } from 'qs'

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route)
  return resolvedRoute.path === router.currentRoute.path
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

export const debounce = (func, timeout) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => func(...args), timeout)
  }
}

export const onlyWithValue = object => {
  const newObject = {}
  Object.entries(object).forEach(([key, value]) => {
    if (isObject(value)) {
      const data = onlyWithValue(value)
      if (Object.keys(data).length) {
        newObject[key] = data
      }
    } else if (value !== null && value !== '') {
      newObject[key] = value
    }
  })
  return newObject
}

export const convertObjectToQueryParams = (params, prefix) => {
  const query = Object.keys(params).map(key => {
    const value = params[key]

    // eslint-disable-next-line no-param-reassign
    if (params.constructor === Array) key = `${prefix}[]`
    // eslint-disable-next-line no-param-reassign
    else if (params.constructor === Object) key = (prefix ? `${prefix}[${key}]` : key)

    if (typeof value === 'object') return convertObjectToQueryParams(value, key)
    return `${key}=${encodeURIComponent(value)}`
  })

  // eslint-disable-next-line prefer-spread
  return [].concat.apply([], query).join('&')
}

export const getStructOfFilter = (fields, withOutFields = ['options']) => {
  const result = {}
  Object.keys(fields).forEach(field => {
    if (isObject(fields[field]) && !withOutFields.includes(field)) {
      result[field] = getStructOfFilter(fields[field])
    }
  })
  return Object.keys(result).length ? result : null
}

export const scrollToError = (ctx, validationObserverRef) => {
  ctx.$nextTick(() => {
    const errors = Object.entries(validationObserverRef.errors)
      .map(([key, value]) => ({ key, value }))
      .filter(error => error.value.length)

    if (!validationObserverRef.refs[errors[0]?.key]) return

    validationObserverRef.refs[errors[0].key].$el.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  })
}

export const convertDataFormTStoStr = date => [date.getFullYear(), date.getMonth() + 1, date.getDate()].map(x => x.toString().padStart(2, '0')).join('-')

export const mapDate = date => {
  if (!date) return null
  const getDate = new Date(date)

  return [getDate.getFullYear(), getDate.getMonth() + 1, getDate.getDate()].map(x => x.toString().padStart(2, '0')).join('-')
}

export const getProps = (fieldName, fields, hasParent = false, childName = '', getParentValue = () => {}) => {
  // fieldName => fields' filed
  // fields => Object which will be generated new Mapped Object
  // hasParent => it means that whether this filed depends on another field's value
  // childName => Parent's child field
  // getParentValue => callback Function that returns parent's value to child

  const generateFieldObject = {
    is: fields[fieldName].type,
    field: fields[fieldName],
    name: fieldName,
  }

  if (hasParent && childName) {
    generateFieldObject.parentValue = getParentValue()
  }

  return generateFieldObject
}

export const getItemFromGivenArray = (neededItemId, arrayOfItems) => {
  if (!neededItemId || !arrayOfItems) return

  // eslint-disable-next-line consistent-return
  return arrayOfItems.find(({ id }) => Number(id) === Number(neededItemId))
}

export const sumTotalAmountOfGivenArrayByProperty = (listForSummation = [], itemQtyKeyName) => listForSummation?.reduce(
  // eslint-disable-next-line radix
  (accumulator, currentValue) => {
    if (currentValue[itemQtyKeyName]) {
      return accumulator + +currentValue[itemQtyKeyName]
    }
    return accumulator + 0
  },
  0,
)

export const customActionGet = (endpoint, params = {}, callback, mutationName = null) => new Promise((resolve, reject) => {
  axios.get(endpoint, {
    params,
    paramsSerializer: parameters => stringify(parameters),
  })
    .then(response => {
      if (mutationName) {
        callback(mutationName, response.data.data)
      }
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const customActionDel = (endpoint, params = {}) => new Promise((resolve, reject) => {
  axios.delete(endpoint, {
    params,
    paramsSerializer: parameters => stringify(parameters),
  })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

/**
 Retrieves the value from a given object based on a nested key string.
 @param {Object} object - The object from which to retrieve the value.
 @param {string} key - The key string specifying the nested path to the desired value, it can be function while we need custom change return result
 @param onNotFoundValueMsg - while value not found returns this message
 @returns {*} - The value found at the specified nested key path, or a default value of " Not Found " if the key is not found.
 */

export const getValueFromGivenObjectByKey = (object, key, onNotFoundValueMsg = '—') => {
  if (!key || !object) return
  if (typeof key === 'function') {
    // eslint-disable-next-line consistent-return
    return key(object)
  }
  const keys = key.split('.')
  let value = object

  for (let i = 0; i < keys.length; i++) {
    if (!value[keys[i]]) return onNotFoundValueMsg

    value = value[keys[i]]
  }
  // eslint-disable-next-line consistent-return
  return value
}

/**
 @returns {string} - Returns converted number to currency view
 * @param price
 * @param currency
 * @param style
 * @param locales
 */

export function formatNumberToCurrencyView(price, currency = 'USD', style = 'currency', locales = 'en-US') {
  if (!price) return '0'

  return new Intl.NumberFormat(locales, {
    style,
    currency,
  }).format(price) ?? '0'
}

/**
 * returns formatted date by default => Jul 10, 2023, 2:26 PM
 * @param locales
 * @param month
 * @param day
 * @param year
 * @param hour
 * @param minute
 * @param hour12
 */

export function getCurrentFormattedDate(
  locales = 'en-US',
  month = 'short',
  day = '2-digit',
  year = 'numeric',
  hour = 'numeric',
  minute = 'numeric',
  hour12 = true,
) {
  const now = new Date()

  return now.toLocaleString(locales, {
    month, day, year, hour, minute, hour12,
  })
}

export function formatDate(dateString) {
  const date = new Date(dateString)
  return date.toLocaleString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  })
}

/**
 * This is just enhancement over Object.extend [Gives deep extend]
 * @param {target} a Object which contains values to be overridden
 * @param {source} b Object which contains values to override
 */
// export const objectExtend = (a, b) => {
//   // Don't touch 'null' or 'undefined' objects.
//   if (a == null || b == null) {
//     return a
//   }

//   Object.keys(b).forEach(key => {
//     if (Object.prototype.toString.call(b[key]) === '[object Object]') {
//       if (Object.prototype.toString.call(a[key]) !== '[object Object]') {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = b[key]
//       } else {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = objectExtend(a[key], b[key])
//       }
//     } else {
//       // eslint-disable-next-line no-param-reassign
//       a[key] = b[key]
//     }
//   })

//   return a
// }

export const checkIsAllRequiredFieldsFilled = (
  formObject,
  requiredFieldsList,
  ctx,
  moduleName,
  actionName,
) => {
  const everyObjectKeyWords = Object.keys(formObject)
  const isExistedNeededFields = []

  for (let i = 0; i < requiredFieldsList.length; i += 1) {
    for (let j = 0; j < everyObjectKeyWords.length; j += 1) {
      if (requiredFieldsList[i] === everyObjectKeyWords[j]) {
        isExistedNeededFields.push(true)
      }
    }
  }

  const checkIfExistsAllNeededFields = isExistedNeededFields.every(item => item)
      && isExistedNeededFields.length === requiredFieldsList.length

  ctx.$store.commit(
    `${moduleName}/${actionName}`,
    checkIfExistsAllNeededFields,
  )
}
